<template>
  <section>
    <div class="d-flex">
      <v-checkbox
        color="black"
        :value="item.addedToCheckout"
        v-model="item.addedToCheckout"
        v-if="!loadingTotal && checkout == undefined"
        class="chkBox"
        @change="toggleCheckout('', item.userHandle)"
      ></v-checkbox>
      <v-progress-circular
        indeterminate
        :size="25"
        class="mr-2"
        v-if="loadingTotal"
        :width="2"
      ></v-progress-circular>
      <!-- <strong class="mb-5">{{ item.businessName }}</strong> -->
      <strong class="mb-5">EV Steer</strong>
    </div>
    <div
      class="offerCard"
      v-for="(data, index) in item.products"
      :key="index + 'cart'"
    >
      <v-checkbox
        color="black"
        :value="data.addedToCheckout"
        v-model="data.addedToCheckout"
        v-if="!loadingTotal && checkout == undefined"
        class="chkBox"
        @change="toggleCheckout(data._id)"
      ></v-checkbox>
      <v-progress-circular
        indeterminate
        :size="25"
        class="mr-2"
        v-if="loadingTotal"
        :width="2"
      ></v-progress-circular>
      <div class="image">
        <router-link :to="`/product/${data.productId}`">
          <img :src="data.image" alt="" />
        </router-link>
      </div>
      <div class="offerDescription">
        <div>
          <router-link :to="`/product/${data.productId}`">
            <p class="offerTitle">{{ data.productName }}</p></router-link
          >
          <span class="storename">Rs. {{ data.price }}</span>
          <span class="variantSelection">Quantity : {{ data.quantity }}</span>
        </div>
        <div v-if="checkout == undefined">
          <v-progress-circular
            indeterminate
            v-if="cartLoading"
          ></v-progress-circular>
          <ul class="actionBtn" v-if="!cartLoading">
            <li>
              <v-icon class="borderBtn" @click="cartAction('decrement',data.productId)">
                mdi-minus
              </v-icon>
            </li>
            <li>{{ data.quantity }}</li>
            <li>
              <v-icon
                class="borderBtn"
                @click="cartAction('increment',data.productId)"
                v-if="!cartLoading"
              >
                mdi-plus
              </v-icon>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CartCard",
  props: ["info", "item","checkout"],
  data: () => ({
    addedToCart: false,
    valueCart: 0,
    cartLoading: false,
    quantity: 1,
    loading: true,
    loadingTotal: false,
  }),
  methods: {
    ...mapActions(["postCart", "postCheckout"]),
    async cartAction(actionType,productId) {
      this.cartLoading = true;
      let data = {};
      data.productId = productId;
      data.quantity = this.quantity;
      data.actionType = actionType;
      await this.postCart(data);
      this.cartLoading = false;
 
    },
    async toggleCheckout(cartId, businessHandle) {
      this.loadingTotal = true;
      if (cartId != "") {
        await this.postCheckout({ cartId: cartId });
      } else {
        await this.postCheckout({ businessHandle: businessHandle });
      }

      this.loadingTotal = false;
    },
    removeToCart() {
      if (this.valueCart >= 1) {
        this.valueCart -= 1;
      }
      if (this.valueCart < 1) {
        this.addedToCart = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chkBox {
  margin-top: -5px;
}
section {
  padding-bottom: 20px;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.offerCard {
  display: flex;
  justify-content: flex-start;
  .image {
    width: 120px;
    height: 120px;
    background: gray;
    border-radius: 10px;
    img {
      object-fit: cover;
      width: 120px;
      height: 120px;
      border-radius: 8px;
    }
    margin-right: 10px;
  }
  .offerDescription {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    .offerTitle {
      color: rgba(26, 26, 26, 1);
      font-weight: 700;
      font-size: 180x;
      text-transform: capitalize;
    }
    .price {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      p {
        font-size: 14px;
      }
    }
  }
  .v-btn {
    font-size: 13px;
    height: 50px;
    width: 130px;
    .v-icon {
      font-size: 16px;
      margin-left: 5px;
    }
    background: #0878d4 !important;
    color: #fff;
  }
}
.text-red {
  color: rgba(197, 46, 127, 1);
  font-weight: 700;
}
.rating {
  margin-bottom: 5px;
  margin-left: -5px;
}
.salePrice {
  position: relative;
  text-decoration: line-through;
  &::after {
    position: absolute;
    text-decoration: none;
  }
}
.storename {
  color: #8c8c8c;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
.status.on {
  color: green;
  font-size: 14px;
}

.variantSelection {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: block;
}
.borderBtn {
  border: 1px solid #b3b3b3;
  padding: 3px;
  border-radius: 5px;
}
.actionBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;
  padding-left: 0;
  li {
    margin: 0;
    &:nth-of-type(2) {
      width: 40px;
      text-align: center;
    }
  }
  .v-icon {
    font-size: 22px;
  }
}
.desc {
  margin-top: 10px;
  ul{
    padding-left: 0;
    li{
      span{
        color: #8c8c8c;
        font-size: 15px;
      }
    }
  }
  p {
    font-size: 14px;
    color: #8c8c8c;
  }
}
</style>