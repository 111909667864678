<template>
  <section>
    <v-container class="container-custom mb-10">
      <h1>My Cart</h1>
      <v-row>
        <v-col md="12" v-if="loading">
          <div class="shadowBox">loading</div>
        </v-col>
        <v-col md="7" v-if="!loading">
          <div class="shadowBox">
          
            <div
              class="item"
              v-for="(data, index) in allCarts.results"
              :key="index + 'cart'"
            >
              <CartItem :item="data" />
            </div>
            <div v-if="allCarts.results.length == 0" class="noCart">
              <v-icon> mdi-timer-sand-empty </v-icon>
              No product added to your cart
            </div>
          </div>
        </v-col>
        <v-col md="5" v-if="!loading">
          <div class="totalCalculation">
            <h3>Payment</h3>
            <ul>
              <li>
                <span>Subtotal</span>
                <strong>Rs. {{allCarts.details.subTotal}}</strong>
              </li>
              <li>
                <span>Discount</span>
                <strong>Rs. {{allCarts.details.totalDiscount}}</strong>
              </li>
              <li>
                <span>Shipping</span>
                <strong>Rs. {{allCarts.details.shipping}}</strong>
              </li>
              <li>
                <span>Total</span>
                <strong>Rs. {{allCarts.details.total}}</strong>
              </li>
            </ul>
            <div class="defaultBtn" v-if="allCarts.details.total > 0">
              <router-link  to="/checkout">
                <v-btn>Checkout</v-btn>
              </router-link>
            </div>
            <div class="defaultBtn" v-if="allCarts.details.total == 0">
              Please select product for checkout
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import CartItem from "@/components/CartItem";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Cart",
  data: () => ({
    loading: true,
  }),
  components: {
    CartItem,
  },
  methods: {
    ...mapActions(["getCarts"]),
  },
  computed: {
    ...mapGetters(["allCarts"]),
  },

  async created() {
    if (this.allCarts == undefined) {
      await this.getCarts();
      this.loading = false;
    }else{
      this.loading = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.noCart {
  display: flex;
  align-items: center;
  .v-icon {
    margin-right: 15px;
  }
}
h1 {
  font-size: 30px;
  padding: 20px 0;
}
.snakbar {
  z-index: 999;
}
ul {
  padding-left: 0;
}
.totalCalculation {
  width: 100%;
  font-size: 16px;

  h3 {
    margin-bottom: 20px;
    margin-top: 25px;
    color: #0878d4;
  }
  ul {
    display: flex;
    flex-flow: column;
    padding-left: 0;
    margin-bottom: 25px;
    li {
      margin: 0;
      display: flex;
      justify-content: space-between;
      border: 1px solid #efefefef;
      border-left: 0;
      border-right: 0;
      padding: 10px 0;
      span {
        color: #8c8c8c;
      }
      strong {
        font-weight: 500;
      }
      &:last-of-type {
        strong {
          font-weight: 700;
        }
      }
    }
  }
}
.companyDetail {
  padding: 0 20px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
}
.cartInfo {
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;

  border-bottom: 1px solid #efefef;
  .cartBtn {
    color: #2ec574;
    font-weight: 600;
    font-size: 14px;
  }
}
.item {
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  padding: 15px 0;

  &:last-of-type {
    border-bottom: none;
  }
}
.defaultBtn {
  margin-bottom: 80px;
  .v-btn {
    width: 100%;
  }
}
a {
  .v-btn {
    min-height: 60px;
    width: 150px;
  }
}
.totalCalculation {
  h3 {
    &:first-of-type {
      margin-top: 0;
    }
  }
  padding: 20px 20px;
  box-shadow: 0 0 5px #d0cece;
  border-radius: 8px;
  .defaultBtn {
    margin-bottom: 0;
  }
}
.shadowBox {
  box-shadow: 0 0 5px #d0cece;
  padding: 20px;
  margin-bottom: 50px;
  border-radius: 8px;
}
.v-btn{
  color: #fff;
}
</style>
