<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> My Profile</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="3">
            <Navigation />
          </v-col>
          <v-col md="9" cols="12">
            <h2 class="mb-5">My Profile</h2>
            <div class="filterationBox">
              <ul class="detailList">
                <li>
                  <span>Full Name</span>
                  <span v-if="loading">loading...</span>
                  <span v-if="!loading && !nameEdit" @click="nameAction"
                    >{{ userDetail.fullname }}
                    <v-icon> mdi-square-edit-outline </v-icon></span
                  >
                  <span v-if="nameEdit" class="d-flex align-center"
                    ><v-text-field
                      dense
                      v-model="userDetail.fullname"
                      outlined
                    ></v-text-field>
                    <v-btn
                      class="ml-1 px-2 primaryBtn"
                      @click="updateProfile"
                      v-if="!loadingSubmit"
                      >Done</v-btn
                    >
                    <v-btn
                      v-if="loadingSubmit"
                      class="ml-1 px-2 primaryBtn"
                      loading
                    ></v-btn>
                  </span>
                </li>
                <li>
                  <span>Phone</span>
                  <span v-if="loading">loading...</span>
                  <span v-if="!loading && !phoneEdit"
                    >{{ userDetail.phoneNumber }}
                    <v-icon @click="phoneAction">
                      mdi-square-edit-outline
                    </v-icon></span
                  >
                  <span v-if="phoneEdit" class="d-flex align-center"
                    ><v-text-field
                      dense
                      v-model="userDetail.phoneNumber"
                      outlined
                    ></v-text-field>
                    <v-btn
                      class="ml-1 px-2 primaryBtn"
                      @click="updateProfile"
                      v-if="!loadingSubmit"
                      >Done</v-btn
                    >
                    <v-btn
                      v-if="loadingSubmit"
                      class="ml-1 px-2 primaryBtn"
                      loading
                    ></v-btn>
                  </span>
                </li>
                <li>
                  <span>Email</span>
                  <span v-if="loading">loading...</span>
                  <span v-if="!loading">{{ userDetail.email }} </span>
                </li>
                <li>
                  <span>Gender</span>
                  <span v-if="loading">loading...</span>
                  <span v-if="!loading && !genderEdit"
                    >{{ userDetail.gender }}
                    <v-icon @click="genderAction">
                      mdi-square-edit-outline
                    </v-icon></span
                  >
                  <span v-if="genderEdit" class="d-flex align-center"
                    ><v-select
                      dense
                      :items="['Male', 'Female', 'Other']"
                      v-model="userDetail.gender"
                      outlined
                    ></v-select>
                    <v-btn
                      class="ml-1 px-2 primaryBtn"
                      @click="updateProfile"
                      v-if="!loadingSubmit"
                      >Done</v-btn
                    >
                    <v-btn
                      v-if="loadingSubmit"
                      class="ml-1 px-2 primaryBtn"
                      loading
                    ></v-btn>
                  </span>
                </li>
                <li>
                  <span>Birthday</span>
                  <span v-if="loading">loading...</span>
                  <span v-if="!loading && !dobEdit"
                    >{{ userDetail.dob }}
                    <v-icon @click="dobAction">
                      mdi-square-edit-outline
                    </v-icon></span
                  >
                  <span v-if="dobEdit" class="d-flex align-center">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="userDetail.dob"
                          label="Date of Birth"
                          readonly
                          dense
                          v-bind="attrs"
                          v-on="on"
                          outlined
                             
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="userDetail.dob"
                        max="2006-01-01"
                        min="1950-01-01"
                        :active-picker.sync="activePicker"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                    <v-btn
                      class="ml-1 px-2 primaryBtn"
                      @click="updateProfile"
                      v-if="!loadingSubmit"
                      >Done</v-btn
                    >
                    <v-btn
                      v-if="loadingSubmit"
                      class="ml-1 px-2 primaryBtn"
                      loading
                    ></v-btn>
                  </span>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import Navigation from "@/components/user/Navigation";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyProfile",
  data: () => ({
    userDetail: undefined,
    loading: true,
    nameEdit: false,
    phoneEdit: false,
    genderEdit: false,
    dobEdit: false,
    loadingSubmit: false,
    date: null,
    menu: false,
    activePicker: null,
  }),
  components: {
    Navigation,
  },
  computed: {
    ...mapGetters(["allUserDetail"]),
  },
  methods: {
    ...mapActions(["getUserDetail", "putUserDetail"]),
    async updateProfile() {
      this.loadingSubmit = true;
      let data = {};
      if (this.nameEdit) {
        data.fullname = this.userDetail.fullname;
      }
      if (this.phoneEdit) {
        data.phoneNumber = this.userDetail.phoneNumber;
      }
      if (this.genderEdit) {
        data.gender = this.userDetail.gender;
      }
      if (this.dobEdit) {
        data.dob = this.userDetail.dob;
      }
      await this.putUserDetail(data);
      this.nameEdit = false;
      this.phoneEdit = false;
      this.genderEdit = false;
      this.dobEdit = false;
      this.loadingSubmit = false;
    },
    nameAction() {
      this.nameEdit = !this.nameEdit;
    },
    phoneAction() {
      this.phoneEdit = !this.phoneEdit;
    },
    genderAction() {
      this.genderEdit = !this.genderEdit;
    },
    dobAction() {
      this.dobEdit = !this.dobEdit;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  async created() {
    await this.getUserDetail();
    this.userDetail = this.allUserDetail.results;
    this.userDetail.dob = this.userDetail.dob.slice(0,10)
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 15px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .detailList {
    padding-left: 0;
    margin-bottom: 10px;
    li {
      color: #8c8c8c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      font-size: 14px;
      .v-icon {
        margin-left: 10px;
        font-size: 20px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
.primaryBtn {
  cursor: pointer;
  margin-bottom: 26px;
  width: 56px;
}
</style>